import styles from './care-homes-bst.module.css';
import classNames from 'classnames/bind';
import { Button, Loader, PatientButton, TopMenu } from '../../components';
import {
  useAddPatientFromBstPopup,
  useBlindSignupToken,
  useMaxBstTriesReached,
  useSessionValue,
  useShowErrorPopup,
} from '../../hooks';
import { BstPatientJwt, SelectOption } from 'src/ts';
import { Select } from '../../components/selects';
import { useTranslation } from 'react-i18next';
import { useNextStepBstPopup } from '../../hooks/popup/bst';
import Callcenter from '../../img/HelpImg/helpLuracare.png';

const cx = classNames.bind(styles);

export const CareHomesBst = () => {
  const { t } = useTranslation();

  const {
    bstInfo,
    isBstInfoLoading,
    verifyPatientBirthDateService,
    isVerifyPatientBirthDateServiceLoading,
    setSelectedBstPatient,
    selectedBstPatient,
    birthDateConfirmation,
    setBirthDateConfirmation,
  } = useBlindSignupToken();
  const { showErrorPopup } = useShowErrorPopup();
  const { showMaxBstTriesReachedPopup } = useMaxBstTriesReached();
  const { showAppPatientFromBstPopup } = useAddPatientFromBstPopup();
  const { isUserLogged } = useSessionValue();
  const { showNextStepBstPopup } = useNextStepBstPopup();
  const selectOptions: SelectOption<BstPatientJwt>[] = (bstInfo?.contactList ?? [])
    .sort((a, b) => (a.contactName > b.contactName ? 1 : -1))
    .map(
      patient =>
        ({
          value: patient,
          content: <PatientButton patient={patient} />,
          searchValue: patient.contactName,
        } as SelectOption<BstPatientJwt>),
    );

  const handleSelectPatient = (patient: BstPatientJwt) => {
    setSelectedBstPatient(patient);
    setBirthDateConfirmation('');
  };

  const handleConfirmClick = () => {
    verifyPatientBirthDateService({
      onSuccess: () => {
        if (isUserLogged) {
          showAppPatientFromBstPopup();
          return;
        }
        showNextStepBstPopup();
        // navigate('/signup');
      },
      onError: error => {
        if (error.message === 'modal.addPatientFromBst.errors.maxTriesReached') {
          showMaxBstTriesReachedPopup();
          return;
        }
        showErrorPopup(error.message);
      },
      onSettled: data => console.log('onSettled', data),
    });
  };

  return (
    <div className={cx('main-container')}>
      <TopMenu title={t('')} />
      <div className={cx('container')}>
        {isBstInfoLoading && bstInfo == null ? (
          <div className={cx('content-container')}>
            <p>{'Procesando datos, puede demorarse unos minutos, porfavor espere.'}</p>
            <Loader />
          </div>
        ) : (
          <div className={cx('content-container')}>
            <div className={cx('title-container')}>
              <h4 className={cx('title')}>{t('pages.residencias.title')}:</h4>
              <h3 className={cx('care-home-name')}>{bstInfo?.carehomeName}</h3>
            </div>

            <div className={cx('img-container')}>
              <img src={Callcenter} className={cx('img')} alt='help image' />
            </div>

            <div className={cx('info-container')}>
              <p>{t('pages.residencias.info_1')}</p>
              <p>{t('pages.residencias.info_2')}</p>
            </div>

            <div className={cx('patients-container')}>
              <Select
                label={t('pages.residencias.selectRelative')}
                setValue={handleSelectPatient}
                options={selectOptions}
                search={{ visible: true }}
                size='large'
              />
            </div>

            {selectedBstPatient != null ? (
              <div className={cx('confirm-container', 'bs-30')}>
                <div className={cx('date-container')}>
                  <p>{t('pages.residencias.confirmBirthdate')}</p>
                  {selectedBstPatient.count > 1 ? (
                    <p className={cx('warning-text')}>
                      {t('pages.residencias.multiplePatientsSameName', {
                        count: selectedBstPatient.count,
                      })}
                    </p>
                  ) : null}
                  <input
                    className={cx('date-input')}
                    type='date'
                    value={birthDateConfirmation}
                    onChange={e => setBirthDateConfirmation(e.currentTarget.value)}
                  />
                </div>

                <Button
                  fullWidth={true}
                  onClick={handleConfirmClick}
                  isLoading={isVerifyPatientBirthDateServiceLoading}
                  disabled={birthDateConfirmation === '' || selectedBstPatient == null}
                >
                  {t('confirm')}
                </Button>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};
