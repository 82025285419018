// CSS
import '../../styles/Login.css';
import styles from './login.module.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

// Components
import { LoginInput, LoginSignupSwitchLink } from '../../components';
import { LoginSignupLayout } from '../../components/login-signup/login-signup-layout/login-signup-layout';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Interfaces
import { LoginErrors } from '../../hexagonal-architecture-frontend-base/src/domain/models/User';
import { UserLogin } from '../../hexagonal-architecture-frontend-base/src/domain/models/User';

// Hooks
import { useBlindSignupToken, usePatientsValue, useSession } from '../../hooks';

import { Button } from '../../components';
import { useLogin } from '../../hooks';
import { validate } from '../../utils';

const cx = classNames.bind(styles);

export const Login = () => {
  // Constants
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSession } = useSession();
  const { login } = useLogin();
  const { cleanBstInfo, verifyPatientBirthDate } = useBlindSignupToken();

  const [fieldIsValid, setFieldIsValid] = useState<LoginErrors>({
    validMobile: true,
    validPassword: true,
  });
  const [isTryingToLogin, setIsTryingToLogin] = useState<boolean>(false);
  const [user, setUser] = useState<UserLogin>({ username: '', password: '' });
  const [usernameError, setUsernameError] = useState('');

  /************
   * Handlers *
   ************/
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsTryingToLogin(true);
    setUsernameError('');

    login(
      { ...user, verifyPatientBirthDate },
      {
        onSuccess: loginResponse => {
          cleanBstInfo();
          console.log('loginResponse', loginResponse);
          setSession(loginResponse.auth, loginResponse.userInfo);
          if (loginResponse.blindSignup?.contactId) {
            navigate(`/informes/${loginResponse.blindSignup.contactId}`);
            return;
          }
          navigate('/personas');
        },
        onError: error => {
          // showErrorPopup(error.message);
          setUsernameError(error.message);
        },
        onSettled: () => setIsTryingToLogin(false),
      },
    );
  };

  const handleValidation = (field: 'username' | 'password', value: string) => {
    if (value) {
      setUsernameError('');
      if (field === 'username') {
        setFieldIsValid(error => ({ ...error, validMobile: validate(value, 'phone') }));
      } else {
        setFieldIsValid(error => ({ ...error, validPassword: true }));
      }
    }
    setUser(user => ({ ...user, [field]: value }));
  };

  /**************
   * JSX Values *
   **************/
  document.title = `LuraCare - ${GlobalService.uppercaseFirstLetter(t('pages.login.title'))}`;

  let isButtonEnabled = true;

  const fieldsDefinition = [
    {
      label: 'phoneno',
      enableShowPassword: false,
      errorField: 'validMobile',
      errorMessage: 'errors.phoneInvalid',
      id: 'username',
      placeholder: 'phoneno',
      type: 'tel',
    },
    {
      label: 'password',
      enableShowPassword: true,
      errorField: 'validPassword',
      errorMessage: 'errors.passwordInvalid',
      id: 'password',
      placeholder: 'password',
      type: 'password',
    },
  ];

  Object.keys(user).forEach(key => {
    if (!user[key as keyof UserLogin]) {
      isButtonEnabled = false;
    }
  });

  if (isButtonEnabled) {
    Object.keys(fieldIsValid).forEach(key => {
      if (!fieldIsValid[key as keyof LoginErrors]) {
        isButtonEnabled = false;
      }
    });
  }

  /*******
   * JSX *
   *******/
  return (
    <LoginSignupLayout>
      <form className={cx('login-form')} onSubmit={handleSubmit}>
        {usernameError ? <div className='text-red-500 text-sm'>{t(usernameError)}</div> : null}

        {fieldsDefinition.map((item, index) => (
          <LoginInput
            key={index}
            id={item.id as 'username' | 'password'}
            label={item.label}
            placeholder={item.placeholder}
            type={item.type as 'password' | 'text'}
            enableShowPassword={item.enableShowPassword}
            value={user[item.id as 'username' | 'password']}
            onChange={handleValidation}
            errorMessage={
              !fieldIsValid[item.errorField as 'validMobile' | 'validPassword']
                ? item.errorMessage
                : null
            }
          />
        ))}

        {/* <Link to={'/forgotpassword'} className='flex forgot-link mt-2 justify-end'>
          {GlobalService.uppercaseJustFirstLetter(t('pages.login.forgotPassword'))}
        </Link> */}

        <Button
          type='submit'
          styleType='primary'
          disabled={!isButtonEnabled}
          fullWidth={true}
          isLoading={isTryingToLogin}
        >
          {GlobalService.uppercaseFirstLetter(t('pages.login.title'))}
        </Button>
      </form>

      <LoginSignupSwitchLink type='login' />
    </LoginSignupLayout>
  );
};
